import { render, staticRenderFns } from "./InboxList.vue?vue&type=template&id=08e5a8e1&scoped=true&"
import script from "./InboxList.vue?vue&type=script&lang=js&"
export * from "./InboxList.vue?vue&type=script&lang=js&"
import style0 from "./InboxList.vue?vue&type=style&index=0&id=08e5a8e1&prod&lang=scss&scoped=true&"
import style1 from "./InboxList.vue?vue&type=style&index=1&id=08e5a8e1&prod&lang=css&"
import style2 from "./InboxList.vue?vue&type=style&index=2&id=08e5a8e1&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08e5a8e1",
  null
  
)

export default component.exports
import QChip from 'quasar/src/components/chip/QChip.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QCircularProgress from 'quasar/src/components/circular-progress/QCircularProgress.js';
import QChatMessage from 'quasar/src/components/chat/QChatMessage.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QChip,QAvatar,QMenu,QIcon,QCircularProgress,QChatMessage});
